/* Tailwind base and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Button base styles */
.btn {
  @apply bg-fuchsia-500 hover:bg-fuchsia-600 text-white font-bold py-2 px-4 rounded;
}

/* Small button */
.btn-sm {
  @apply text-sm py-1 px-2;
}

/* Large button */
.btn-lg {
  @apply text-lg py-3 px-5;
}

/* Outline button */
.btn-outline {
  @apply border border-fuchsia-500 text-fuchsia-500 bg-transparent hover:bg-fuchsia-500 hover:text-white;
}

/* Text styles */

/* Headings */
.h1 {
  @apply text-3xl font-bold text-gray-800;
}

.h2 {
  @apply text-2xl font-semibold text-gray-700;
}

.h3 {
  @apply text-xl font-semibold text-gray-600;
}

/* Paragraphs */
.p {
  @apply text-base text-gray-700;
}

/* Muted or subtle text */
.text-muted {
  @apply text-sm text-gray-500;
}

/* Container classes */

/* Full-width container */
.container-full {
  @apply w-full mx-auto px-4;
}

/* Centered container with max-width */
.container {
  @apply max-w-7xl mx-auto px-4;
}

/* Card component styles */

/* Card component */
.card {
  @apply bg-white rounded-lg shadow-md p-6 border border-gray-200;
}

/* Card header */
.card-header {
  @apply font-bold text-xl text-gray-800;
}

/* Card body */
.card-body {
  @apply text-base text-gray-700;
}

/* Card footer */
.card-footer {
  @apply text-right text-gray-500;
}

/* Input and form styles */

/* Input fields */
.input {
  @apply border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-fuchsia-500;
}

/* Form labels */
.label {
  @apply block text-sm font-medium text-gray-700;
}

/* Form error text */
.error-text {
  @apply text-sm text-red-500 mt-1;
}

/* Alerts */

/* Success alert */
.alert-success {
  @apply bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative;
}

/* Error alert */
.alert-error {
  @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
}

/* Warning alert */
.alert-warning {
  @apply bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative;
}

/* Link styles */

/* Primary link */
.link {
  @apply text-fuchsia-500 hover:text-fuchsia-600 underline;
}

/* Muted link */
.link-muted {
  @apply text-gray-500 hover:text-gray-600 underline;
}
